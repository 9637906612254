import Section from "../Sections/Section/Section";

export default function NotFound(){
  return (
    <Section
      p={60}
      textAlign='center'
    >
      <h2>404 NOT FOUND</h2>
    </Section>
  )
}
